import "../styles/globals.css";
import "../styles/styles.css";
import { Provider } from "react-redux";
import { createWrapper } from "next-redux-wrapper";
import { store } from "../redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-range-slider-input/dist/style.css";
import Script from "next/script";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }) {
    
    return (
        <>
            <Provider store={store}>
                {/* <PersistGate persistor={persistor}> */}
                <QueryClientProvider client={queryClient}>
                  <Component {...pageProps} />
                </QueryClientProvider>
                {/* </PersistGate> */}
                <ToastContainer />
            </Provider>
            {/* <!-- This site is converting visitors into subscribers and customers with https://respond.io --> */}
            
            <Script
                id="respondio__growth_tool"
                src="https://cdn.respond.io/widget/widget.js?wId=3e654b34-0af3-47c5-9fb4-a62d88e9bf83"
            />
            {/* <!-- https://respond.io --> */}
        </>
    );
}

const makeStore = () => store;
const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(MyApp);
